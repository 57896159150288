/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    ol: "ol",
    li: "li",
    a: "a",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td"
  }, _provideComponents(), props.components), {Boon, Condition, Effect, Divider, Card, Specialization, BuildLink, Skill, Trait, Tabs, Tab, Information} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!BuildLink) _missingMdxReference("BuildLink", true);
  if (!Card) _missingMdxReference("Card", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Divider) _missingMdxReference("Divider", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Information) _missingMdxReference("Information", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Trait) _missingMdxReference("Trait", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Playing in organised groups"), "\n", React.createElement(_components.p, null, "It all comes down to some important concepts that need to be fulfilled permanently while in combat. Failing one of these will slow down the run and affect its smoothness."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Boons: 25 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Boon, {
    name: "Fury"
  }), ", ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n", React.createElement(_components.li, null, "Conditions: 25 stacks of ", React.createElement(Condition, {
    name: "vulnerability"
  }), ", along with any unique condis that your class needs to gain damage modifiers."), "\n", React.createElement(_components.li, null, "Breaking ", React.createElement(_components.a, {
    href: "guides/cc-distribution"
  }, "CC bars"), " to gain the damage modifier from ", React.createElement(Effect, {
    name: "Exposed"
  }), "."), "\n", React.createElement(_components.li, null, "It's extremely important to execute a good boon precast before every boss, which means blasting ", React.createElement(Boon, {
    name: "Might"
  }), ", sharing ", React.createElement(Boon, {
    name: "Quickness"
  }), " and ", React.createElement(Boon, {
    name: "Alacrity"
  }), " on a precast template that has high boon duration, so that these will stay for longer during the encounters."), "\n", React.createElement(_components.li, null, "Supports aren't healers. As a support your job is to deal as much damage as you can while providing the boons you were chosen for."), "\n"), "\n", React.createElement(Divider, {
    text: "Compositions"
  }), "\n", React.createElement(Card, {
    title: "Shattered Observatory and Nightmare CM"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Renegade",
    disableText: true
  })), React.createElement(_components.td, null, React.createElement(BuildLink, {
    build: "Power Renegade",
    specialization: "Renegade"
  }), " provides 100% ", React.createElement(Boon, {
    name: "Alacrity"
  }), " uptime, and around 10 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), ", strong utilities in terms of heavy crowd control and damage mitigation. It applies a very high amount of ", React.createElement(Condition, {
    name: "vulnerability"
  }), " and it remains unmatched for the success of a power composition in short phases. ", React.createElement("br"))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Scrapper",
    disableText: true
  })), React.createElement(_components.td, null, React.createElement(BuildLink, {
    build: "Power Scrapper",
    specialization: "Scrapper"
  }), " provides 100% ", React.createElement(Boon, {
    name: "Quickness"
  }), " uptime, 10 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " with your ", React.createElement(Skill, {
    name: "Blast Gyro",
    id: "31248"
  }), " , utilities in terms of crowd control and reflects. It also provides ", React.createElement(Skill, {
    name: "Superspeed",
    id: "5973"
  }), ", and brings some more ", React.createElement(Condition, {
    name: "vulnerability"
  }), " which helps the renegade.", React.createElement("br"))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Soulbeast",
    disableText: true
  })), React.createElement(_components.td, null, "Two or Three ", React.createElement(BuildLink, {
    build: "Power Soulbeast",
    specialization: "Soulbeast"
  }), " provide massive amounts of CC, the biggest burst in the game for short phases, can keep their boons for longer thanks to ", React.createElement(Trait, {
    name: "Essence of Speed",
    id: "2085"
  }), ". Currently unmatched by any other class on most bosses.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Weaver",
    disableText: true
  }), React.createElement(Specialization, {
    name: "Dragonhunter",
    disableText: true
  })), React.createElement(_components.td, null, "Some decent alternatives to fill the third dps spot in your group, both of these classes bring something valuable, whether it's high burst, faster ", React.createElement(Condition, {
    name: "vulnerability"
  }), " and ", React.createElement(Boon, {
    name: "Aegis"
  }), " from ", React.createElement(BuildLink, {
    build: "Power Dragonhunter",
    specialization: "Dragonhunter"
  }), ", or the late fight damage from ", React.createElement(BuildLink, {
    build: "Power Weaver",
    specialization: "Weaver"
  }), ". Most builds will work well in this slot, providing they can cover the small amount of CC that is missing on the CC heavy encounters (MAMA and Artsariiv)."))))), "\n", React.createElement(Card, {
    title: "Sunqua Peak CM and Silent Surf CM"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Specter",
    disableText: true
  })), React.createElement(_components.td, null, React.createElement(BuildLink, {
    build: "Condi Specter",
    specialization: "Specter"
  }), " is the king of condi alac. This class provides permanent ", React.createElement(Boon, {
    name: "Alacrity"
  }), ", massive CC thanks to ", React.createElement(Skill, {
    name: "Basilisk Venom",
    id: "13132"
  }), ", a lot of ", React.createElement(Condition, {
    name: "vulnerability"
  }), " and healing, doing almost as much dps as an actual dps class. ", React.createElement("br"))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Harbinger",
    disableText: true
  })), React.createElement(_components.td, null, React.createElement(BuildLink, {
    build: "Condi Harbinger",
    specialization: "Harbinger"
  }), " is currently the best ", React.createElement(Boon, {
    name: "Quickness"
  }), " pick. It deals high damage with a fairly short ramp up and has access to large amount of CC, whilst also sharing some ", React.createElement(Boon, {
    name: "Might"
  }), ", and applying ", React.createElement(Condition, {
    name: "Vulnerability"
  }), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Harbinger",
    disableText: true
  }), React.createElement(Specialization, {
    name: "Specter",
    disableText: true
  }), React.createElement(Specialization, {
    name: "Holosmith",
    disableText: true
  }), React.createElement(Specialization, {
    name: "Willbender",
    disableText: true
  }), React.createElement(Specialization, {
    name: "Scrapper",
    disableText: true
  })), React.createElement(_components.td, null, "For the dps spots a range of builds can be effective depending on the encounter and team comp. Make sure to consider things like CC requirements, ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " uptime, and team support when selecting a build.  A special mention goes to ", React.createElement(BuildLink, {
    build: "Power Scrapper",
    specialization: "Scrapper"
  }), " which is played on Dark Ai (Sunqua Peak CM), where it can reflect the Focused Wrath attack for huge damage, which lets  high DPS groups consistently skip the circles mechanic in P1."))))), "\n", React.createElement(Divider, {
    text: "Useful Links"
  }), "\n", React.createElement(_components.p, null, "In this section you can find links to different parts of the site with information relevant to each class and strategies we use for guild runs. This comes in the form of example logs, and PoVs. All CC Schedules used in the PoVs and guides in this section can be found here ", React.createElement(_components.a, {
    href: "/guides/cc-distribution"
  }, "CC-Distribution"), ". If you enjoy this style of gameplay, we are always looking for likeminded players. Check out the ", React.createElement(_components.a, {
    href: "/apply"
  }, "Apply"), " page to see what is required to join us!"), "\n", React.createElement(Card, {
    title: "Example 97+98 PoVs + Logs"
  }, React.createElement(Tabs, null, React.createElement(Tab, {
    specialization: "Renegade"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Boss"), React.createElement(_components.th, null, "PoV"), React.createElement(_components.th, null, "Log"), React.createElement(_components.th, null, "Player"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "MAMA"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/6Wv3rpC7bxU?si=inkLkx2_IlaOsOSb"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/VHzr-20240413-012320_mama"
  }, "DPS.Report")), React.createElement(_components.td, null, "Shavy")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Siax"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/u921ZgeGQys?si=7sL5mV1qoXOXbf74&t=432"
  }, "Link")), React.createElement(_components.td), React.createElement(_components.td, null, "Shavy (Pre Spirit rework)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Ensolyss"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/u921ZgeGQys?si=TfFvGNZVy6rkaBPI&t=468"
  }, "Link")), React.createElement(_components.td), React.createElement(_components.td, null, "Shavy (Pre Spirit rework)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Skorvald"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/rZQHMonXqVg?si=unluit3ptjfw5C6O"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/qgPT-20240404-195220_skor"
  }, "DPS.Report")), React.createElement(_components.td, null, "Hmod")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Artsariiv"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/rZQHMonXqVg?si=DI5-fnf1ao2cDkeY&t=87"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/Qvcp-20240404-195824_arriv"
  }, "DPS.Report")), React.createElement(_components.td, null, "Hmod")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Arkk"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/rZQHMonXqVg?si=dOMmskNj6t1-9_GO&t=148"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/TsNx-20A240404-200150_arkk"
  }, "DPS.Report")), React.createElement(_components.td, null, "Hmod"))))), React.createElement(Tab, {
    specialization: "Scrapper"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Boss"), React.createElement(_components.th, null, "PoV"), React.createElement(_components.th, null, "Log"), React.createElement(_components.th, null, "Player"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "MAMA"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/EEuJEPfhA_w?si=NiMsNKrciedLRAe3"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/APdB-20240130-174854_mama"
  }, "DPS.Report")), React.createElement(_components.td, null, "Agt Endrn")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Siax"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/cRx7MpLdrPU?si=w4ztd04idRidcxlc"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/TqWL-20240112-224615_siax"
  }, "DPS.Report")), React.createElement(_components.td, null, "Agt Endrn (DPS)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Ensolyss"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/6nasIFn00K4?si=o7Zko5-7EEspCpIg"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/JNEX-20240308-003243_enso"
  }, "DPS.Report")), React.createElement(_components.td, null, "Agt Endrn")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Skorvald"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/OipE3PmFUAs?si=QQP0EOasdXR84AWc"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/67U3-20240104-181418_skor"
  }, "DPS.Report")), React.createElement(_components.td, null, "Agt Endrn")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Artsariiv"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/lcB2Eqn1n0Q?si=UpX_qRs4DSaODMSq"
  }, "Link"), " ", React.createElement("br"), " ", React.createElement(_components.a, {
    href: "https://youtu.be/is5wsuCVv4w?si=UZd4OaZhAqiKkHar"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/t7A0-20240114-221537_arriv"
  }, "DPS.Report"), " ", React.createElement("br"), " ", React.createElement(_components.a, {
    href: "https://dps.report/lici-20240316-013722_arriv"
  }, "DPS.Report")), React.createElement(_components.td, null, "Agt Endrn (Slower Strat) ", React.createElement("br"), " Agt Endrn (Faster Strat)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Arkk"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/2yQfJYNS-0k?si=R3cKFj8uJC6kbpQN"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/btSz-20240114-223226_arkk"
  }, "DPS.Report")), React.createElement(_components.td, null, "Agt Endrn"))))), React.createElement(Tab, {
    specialization: "Soulbeast"
  }, React.createElement(Information, null, React.createElement(_components.p, null, "All of these PoVs are currently up to date apart from Artsariiv, due to map items being removed from the game. The rotation doesn't change from the PoV, instead you either dodge, have a ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " use ", React.createElement(Skill, {
    name: "Shield of Courage",
    id: "30029"
  }), ", or your ", React.createElement(Specialization, {
    name: "Scrapper"
  }), " or ", React.createElement(Specialization, {
    name: "Renegade"
  }), " shares ", React.createElement(Boon, {
    name: "Stability"
  }), " and a ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " brings ", React.createElement(Skill, {
    name: "Stone Spirit",
    id: "12495"
  }), ".")), React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Boss"), React.createElement(_components.th, null, "PoV"), React.createElement(_components.th, null, "Log"), React.createElement(_components.th, null, "Player"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "MAMA"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/43Mte41xqS4?si=zvU3k15a9UHlCv2E"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/nmt7-20240120-161225_mama"
  }, "DPS.Report")), React.createElement(_components.td, null, "Jetrell")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Siax"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/43Mte41xqS4?si=9zn7IhCxvyNsq98N&t=57"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/EttO-20240112-214615_siax"
  }, "DPS.Report")), React.createElement(_components.td, null, "Jetrell")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Ensolyss"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/43Mte41xqS4?si=ATun9mOe3BDjk4F6&t=90"
  }, "Link"), " ", React.createElement("br"), " ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=KnLTABI2kJo"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/tv9N-20240120-162526_enso"
  }, "DPS.Report"), " ", React.createElement("br"), " ", React.createElement(_components.a, {
    href: "https://dps.report/HJXn-20240201-192439_enso"
  }, "DPS.Report")), React.createElement(_components.td, null, "Jetrell (Hammer) ", React.createElement("br"), " Wuju (Longbow)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Skorvald"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/ZJl9dMpQ3Ns?si=5IIQU23GUoiFS9qC"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/kmOJ-20240117-191920_skor"
  }, "DPS.Report")), React.createElement(_components.td, null, "Gravi")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Artsariiv"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/B8GTuFvKeD4?si=EKNjvZ_krpNAQGJb"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/kjkL-20240117-195126_arriv"
  }, "DPS.Report")), React.createElement(_components.td, null, "Gravi")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Arkk"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/s3SsWQQILUM?si=0wwDsg8EG0HEbd2o"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/q4D8-20240114-223224_arkk"
  }, "DPS.Report")), React.createElement(_components.td, null, "Wuju"))))), React.createElement(Tab, {
    specialization: "Dragonhunter"
  }, React.createElement(Information, null, React.createElement(_components.p, null, "All of these PoVs are currently up to date apart from Artsariiv. Due to the Consumables being removed and trap changes, CC distrobution has changed, but the PoV is still good to get an idea of the in phase rotation.")), React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Boss"), React.createElement(_components.th, null, "PoV"), React.createElement(_components.th, null, "Log"), React.createElement(_components.th, null, "Player"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "MAMA"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/WYL-AKW5sXY?si=ePMLs7N1sLuZpYFw&t=20"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/nmt7-20240120-161225_mama"
  }, "DPS.Report")), React.createElement(_components.td, null, "Magic")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Siax"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/WYL-AKW5sXY?si=0f9Yt41MiLDOJ5eF&t=87"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/Jp88-20240113-000647_siax"
  }, "DPS.Report")), React.createElement(_components.td, null, "Magic")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Ensolyss"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/WYL-AKW5sXY?si=v1vxKVw95JeHrQAA&t=144"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/Qigu-20240113-001141_enso"
  }, "DPS.Report")), React.createElement(_components.td, null, "Magic")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Skorvald"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/WYL-AKW5sXY?si=9Z5qtszCMaS09Jy-&t=262"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/6Luo-20240117-201926_skor"
  }, "DPS.Report")), React.createElement(_components.td, null, "Magic")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Artsariiv"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/WYL-AKW5sXY?si=RAvlpiqmlBCNfGPn&t=345"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/Xn34-20240124-200143_arriv"
  }, "DPS.Report")), React.createElement(_components.td, null, "Magic")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Arkk"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/WYL-AKW5sXY?si=kULCQocWIxzidxJK&t=424"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/3rZ4-20240117-203308_arkk"
  }, "DPS.Report")), React.createElement(_components.td, null, "Magic"))))))), "\n", React.createElement(Card, {
    title: "Example 99+100CM PoVs + Logs"
  }, React.createElement(Tabs, null, React.createElement(Tab, {
    specialization: "Specter"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Boss"), React.createElement(_components.th, null, "PoV"), React.createElement(_components.th, null, "Log"), React.createElement(_components.th, null, "Player"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "Elemental Ai"), React.createElement(_components.td), React.createElement(_components.td), React.createElement(_components.td)), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Dark Ai"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/xw5L81ow9-U?si=BhiS2Xo8m7IsnQic"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/mf8g-20240203-203815_ai"
  }, "DPS.Report")), React.createElement(_components.td, null, "Wuju (Alacrity)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Kanaxai"), React.createElement(_components.td), React.createElement(_components.td), React.createElement(_components.td))))), React.createElement(Tab, {
    specialization: "Harbinger"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Boss"), React.createElement(_components.th, null, "PoV"), React.createElement(_components.th, null, "Log"), React.createElement(_components.th, null, "Player"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "Elemental Ai"), React.createElement(_components.td), React.createElement(_components.td), React.createElement(_components.td)), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Dark Ai"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/24BkETxUT-Q?si=UniPjn6OI3NqnDnB"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/9oti-20240225-215732_ai"
  }, "DPS.Report")), React.createElement(_components.td, null, "Neno (DPS)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Kanaxai"), React.createElement(_components.td), React.createElement(_components.td), React.createElement(_components.td))))), React.createElement(Tab, {
    specialization: "Scrapper"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Boss"), React.createElement(_components.th, null, "PoV"), React.createElement(_components.th, null, "Log"), React.createElement(_components.th, null, "Player"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "Elemental Ai"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/kMfMWhly_gc?si=ujela9DK7SJPxl2j"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/TqLR-20240118-191001_ai"
  }, "DPS.Report")), React.createElement(_components.td, null, "Agt Endrn (Quickness)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Dark Ai"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/7bEhRqtyO54?si=MVcVrMZVPT2n_ooW"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/2OI7-20240310-205514_ai"
  }, "DPS.Report")), React.createElement(_components.td, null, "Agt Endrn (DPS)"))))), React.createElement(Tab, {
    specialization: "Holosmith"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Boss"), React.createElement(_components.th, null, "PoV"), React.createElement(_components.th, null, "Log"), React.createElement(_components.th, null, "Player"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "Elemental Ai"), React.createElement(_components.td), React.createElement(_components.td), React.createElement(_components.td)), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Dark Ai"), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://youtu.be/bAYiZXz2oHA?si=L3_3hMJobmagvroe"
  }, "Link")), React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "https://dps.report/mf8g-20240203-203815_ai"
  }, "DPS.Report")), React.createElement(_components.td, null, "Shavy")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Kanaxai"), React.createElement(_components.td), React.createElement(_components.td), React.createElement(_components.td))))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
